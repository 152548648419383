import type { Ref } from 'vue'
import { mdAndUp } from '@/composables/breakpoints'

const openPages = ref<Set<string>>(new Set())
const scrollbarHideCSS = `
    ::-webkit-scrollbar {
      display: none;
    }
`
let scrollbarStyleElement: Node | null

/**
 * @description Prevent body overflow when dialog/panel/modal is open
 * @param preventOverflowScroll {Ref<boolean>} - Disable overflow scroll on body
 * @param source {'mobile-menu' | 'ordering-panel'} - Source of the dialog/panel/modal
 * @returns void
 */
export function usePreventBodyOverflow(
  preventOverflowScroll: Ref<boolean>,
  source: 'mobile-menu' | 'ordering-panel'
): void {
  watch(
    [() => preventOverflowScroll.value, () => mdAndUp.value],
    () => {
      // Required on client-side only
      if (import.meta.client) {
        if (preventOverflowScroll.value) {
          openPages.value.add(source)
          document.body.style.overflowY = 'hidden'
          const style = document.createElement('style')
          style.textContent = scrollbarHideCSS
          document.body.appendChild(style)
          scrollbarStyleElement = style
          // Fix for iOS Safari
          // Force body to stay at the same position when the dialog is open
          if (!mdAndUp.value) {
            document.body.style.position = 'fixed'
            document.body.style.height = '100%'
          }
        } else {
          openPages.value.delete(source)
          if (openPages.value.size > 0) return
          document.body.style.overflowY = 'auto'
          document.body.style.position = ''
          if (scrollbarStyleElement && document.body.contains(scrollbarStyleElement)) {
            document.body.removeChild(scrollbarStyleElement)
            scrollbarStyleElement = null
          }

          if (!mdAndUp.value) {
            document.body.style.height = 'auto'
          }
        }
      }
    },
    { immediate: true }
  )
}
